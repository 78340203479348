import { useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useAtom } from "jotai";
import i18n from "i18next";
import {
  bundleFinishedAtom,
  countriesAtom,
  countryAtom,
  currenciesAtom,
  currencyAtom,
  languageAtom,
  languagesAtom,
  localeOverrideAtom,
  showVatAtom,
  specialPriceListAtom,
} from "../state.js";
import { authenticatedAtom, userAtom } from "../atoms/authAtom";

const useSitesConfig = () => {
  const [bundleFinished] = useAtom(bundleFinishedAtom);
  const [, setCountry] = useAtom(countryAtom);
  const [, setCurrency] = useAtom(currencyAtom);
  const [, setLanguage] = useAtom(languageAtom);
  const [countries] = useAtom(countriesAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [languages] = useAtom(languagesAtom);
  const [, setShowVat] = useAtom(showVatAtom);
  const [specialPriceList] = useAtom(specialPriceListAtom);
  const [localeOverride, setLocaleOverride] = useAtom(localeOverrideAtom);
  const [authenticated] = useAtom(authenticatedAtom);
  const [user] = useAtom(userAtom);

  useEffect(() => {
    if (!bundleFinished) {
      return;
    }

    const english = Object.values(languages).find((language) => language.name === "English");
    const swedish = Object.values(languages).find((language) => language.name === "Swedish");

    const eur = Object.values(currencies).find((currency) => currency.iso4217 === "EUR");
    const sek = Object.values(currencies).find((currency) => currency.iso4217 === "SEK");

    let newCurrency = eur.id;

    if (authenticated && user?.b2b) {
      setLanguage(swedish.id);
      i18n.changeLanguage("sv");
      setCountry(Object.values(countries).find((country) => country.country_code === "se").id);
      setCurrency(sek.id);
      setLocaleOverride(true);

      return;
    }

    if (!localeOverride) {
      switch (navigator.language?.split("-")[1] ?? undefined) {
        case "AT":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "at").id);
          i18n.changeLanguage("en");
          break;
        case "BE":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "be").id);
          i18n.changeLanguage("en");
          break;
        case "CZ":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "cz").id);
          i18n.changeLanguage("en");
          break;
        case "DE":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "de").id);
          i18n.changeLanguage("en");
          break;
        case "ES":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "es").id);
          i18n.changeLanguage("en");
          break;
        case "FI":
          newCurrency = sek.id;
          setLanguage(swedish.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "fi").id);
          i18n.changeLanguage("sv");
          break;
        case "FR":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "fr").id);
          i18n.changeLanguage("en");
          break;
        case "LT":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "lt").id);
          i18n.changeLanguage("en");
          break;
        case "LV":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "lv").id);
          i18n.changeLanguage("en");
          break;
        case "LU":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "lu").id);
          i18n.changeLanguage("en");
          break;
        case "NL":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "nl").id);
          i18n.changeLanguage("en");
          break;
        case "NO":
          newCurrency = sek.id;
          setLanguage(swedish.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "no").id);
          i18n.changeLanguage("sv");
          break;
        case "PL":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "pl").id);
          i18n.changeLanguage("en");
          break;
        case "PT":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "pt").id);
          i18n.changeLanguage("en");
          break;
        case "SE":
          newCurrency = sek.id;
          setLanguage(swedish.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "se").id);
          i18n.changeLanguage("sv");
          break;
        case "SI":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "si").id);
          i18n.changeLanguage("en");
          break;
        case "SK":
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "sk").id);
          i18n.changeLanguage("en");
          break;
        default:
          setLanguage(english.id);
          setCountry(Object.values(countries).find((country) => country.country_code === "se").id);
          newCurrency = sek.id;
          i18n.changeLanguage("en");
          break;
      }
      // setCountry(window.sitesConfig.country_id);
      // setCurrency(window.sitesConfig.currency_id);
      // setLanguage(window.sitesConfig.language_id);

      if (specialPriceList && specialPriceList?.currency_id) {
        newCurrency = specialPriceList.currency_id;
      }
      setCurrency(newCurrency);

      setShowVat(true);
    }
  }, [
    authenticated,
    bundleFinished,
    countries,
    currencies,
    languages,
    localeOverride,
    setCountry,
    setCurrency,
    setLanguage,
    setLocaleOverride,
    setShowVat,
    specialPriceList,
    user,
  ]);
};

export default useSitesConfig;
