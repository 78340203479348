import React from "react";
import { useAtom } from "jotai";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Icon, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { countriesAtom, currenciesAtom, showVatAtom } from "./state";

import Title from "./components/Title.js";

import { formattedPrice } from "./utils/price";

const fetchOrders = async ({ queryKey }) => {
  const response = await fetch(`/userapi/orders`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const OrdersPage = () => {
  const [countries] = useAtom(countriesAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [showVat] = useAtom(showVatAtom);

  const { t } = useTranslation();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["orders"],
    queryFn: fetchOrders,
  });

  if (isLoading) return <p>{t("loading")}...</p>;
  if (isError) return <p>Error: {error.message}</p>;

  const orders = data.orders;
  const shipments = data.shipments;

  const total = (order) => {
    let total = 0;
    order.order_rows.forEach((item) => {
      if (showVat) {
        total += Number(item.subtotal_incl_vat);
      } else {
        total += Number(item.subtotal_excl_vat);
      }
    });
    return total;
  };

  const isShipped = (order) => {
    if (order?.shipped) {
      return (
        <>
          <Icon name="check" color="green" />{" "}
          {shipments[order.order_rows[0]]?.tracking_reference}
        </>
      );
    } else {
      return <Icon name="close" color="red" />;
    }
  };

  if (orders.length === 0) {
    return (
      <div>
        <Title title={t("my_orders")} />
        <Message>{t("no_orders")}</Message>
      </div>
    );
  }

  return (
    <div>
      <Title title={t("my_orders")} />
      <Table celled stackable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("order_number")}</Table.HeaderCell>
            <Table.HeaderCell>{t("date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("total_sum")}</Table.HeaderCell>
            <Table.HeaderCell>{t("shipped")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {orders.map((order) => (
            <Table.Row
              key={order.id}
              style={{
                textDecoration: order.cancelled_at ? "line-through" : "none",
              }}
            >
              <Table.Cell>
                <Link to={`/orders/${order.id}`}>{order.id}</Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={`/orders/${order.id}`}>
                  {new Date(order.created_at).toLocaleString()}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={`/orders/${order.id}`}>
                  {formattedPrice(
                    total(order),
                    currencies[order?.currency_id]?.symbol,
                    currencies[order?.currency_id]?.right_side,
                  )}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={`/orders/${order.id}`}>{isShipped(order)}</Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default OrdersPage;
