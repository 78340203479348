import { useState } from "react";
import { Button, Checkbox } from "semantic-ui-react";
import { useQueryClient } from "@tanstack/react-query";

const AdminOrderCancel = ({ order }) => {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const cancelOrder = async () => {
    setLoading(true);
    const response = await fetch(`/api/orders/${order.id}/cancel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    await new Promise((resolve) => setTimeout(resolve, 100));
    setLoading(false);
    queryClient.invalidateQueries();
  };

  return (
    <>
      <Checkbox
        disabled={loading}
        label="Cancel order"
        style={{ marginRight: "2em" }}
        onChange={() => setConfirm(!confirm)}
        checked={confirm}
      />
      <Button
        disabled={!confirm}
        content="Cancel"
        icon="trash"
        labelPosition="left"
        loading={loading}
        color="red"
        onClick={() => {
          cancelOrder();
        }}
      />
    </>
  );
};

export default AdminOrderCancel;
