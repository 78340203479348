import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const bundleFinishedAtom = atom(false);
export const fullBundleFinishedAtom = atom(false);
export const webSocketConnectedAtom = atom(false);

// Config

// export const countryAtom = atom(1);
export const countryAtom = atomWithStorage("country", 1);
// export const currencyAtom = atom(1);
export const currencyAtom = atomWithStorage("currency", 1);
// export const languageAtom = atom(1);
export const languageAtom = atomWithStorage("language", 1);
export const localeModalOpenAtom = atom(false);
export const localeOverrideAtom = atomWithStorage("localeOverride", false);

export const showVatAtom = atom(true);
export const webpSupportedAtom = atom(false);

// Models

export const brandsAtom = atom([]);
export const countriesAtom = atom([]);
export const currenciesAtom = atom([]);
export const inventoryLocationsAtom = atom([]);
export const languagesAtom = atom([]);
export const priceListsAtom = atom([]);

// export const productsAtom = atom({});
export const productsAtom = atom({}, (get, set, update) => {
  const currentState = get(productsAtom);
  set(productsAtom, { ...currentState, ...update });
});

export const productsByIdAtom = atom({}, (get, set, update) => {
  const currentState = get(productsByIdAtom);
  set(productsByIdAtom, { ...currentState, ...update });
});

export const productsByUrlAtom = atom({}, (get, set, update) => {
  const currentState = get(productsByUrlAtom);
  set(productsByUrlAtom, { ...currentState, ...update });
});

export const productGroupsAtom = atom([]);
export const productRelationsAtom = atom([]);
export const shipmentMethodsAtom = atom([]);
export const specialPriceListAtom = atom({});
export const specialPricesAtom = atom([]);
export const suppliersAtom = atom([]);
export const warehousesAtom = atom([]);

// Checkout HTML (Klarna)
export const checkoutHtmlAtom = atom(null);
export const checkoutLoadingAtom = atom(false);
export const checkoutErrorAtom = atom(false);
export const checkoutSuccessAtom = atom(false);

// Product Group page

export const currentProductGroupTagAtom = atom("");
export const productsByProductGroupAtom = atom((get) => {
  const products = get(productsAtom);
  const productGroups = get(productGroupsAtom);
  const currentProductGroupTag = get(currentProductGroupTagAtom);
  const groupId = currentProductGroupTag ? productGroups[currentProductGroupTag].id : null;
  if (!currentProductGroupTag || currentProductGroupTag === "" || !groupId) {
    return products ? products : {};
  }

  const results = {};
  const discontinuedResults = {};

  Object.values(products).forEach((product) => {
    if (product.product_group_ids && product.product_group_ids.includes(groupId)) {
      if (product.discontinued && product.stock === 0) {
        discontinuedResults[product.url] = product;
      } else {
        results[product.url] = product;
      }
    }
  });

  const filteredProducts = {
    active: {},
    discontinued: {},
  };

  Object.values(results)
    .sort((a, b) => {
      return a.part_number.localeCompare(b.part_number);
    })
    .forEach((product) => {
      filteredProducts.active[product.url] = product;
    });

  Object.values(discontinuedResults)
    .sort((a, b) => {
      return a.part_number.localeCompare(b.part_number);
    })
    .forEach((product) => {
      filteredProducts.discontinued[product.url] = product;
    });

  return filteredProducts;
});
