import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, Message } from "semantic-ui-react";
import { shipmentMethodsAtom } from "../state";
import {
  shipmentMethodOptionsAtom,
  shipmentMethodOptionsCountryAtom,
} from "../atoms/shipmentMethodOptionsAtom";
import { userAtom } from "../atoms/authAtom";

const removeShipment = async (shipmentId) => {
  const response = await fetch(`/api/shipments/${shipmentId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const AdminOrderStatus = ({ order, shipments, mutation, picked }) => {
  const [shipmentMethods] = useAtom(shipmentMethodsAtom);
  const [shipmentMethodOptions] = useAtom(shipmentMethodOptionsAtom);
  const [, setShipmentMethodOptionsCountry] = useAtom(
    shipmentMethodOptionsCountryAtom,
  );
  const [user] = useAtom(userAtom);

  const [everythingPicked, setEverythingPicked] = useState(false);
  const [removeShipmentConfirmation, setRemoveShipmentConfirmation] =
    useState(false);
  const [shipmentMethodId, setShipmentMethodId] = useState(
    order?.shipment_method_id,
  );
  const [trackingReference, setTrackingReference] = useState("");

  const queryClient = useQueryClient();

  const removeShipmentMutation = useMutation({
    mutationFn: removeShipment,
    onSuccess: (data) => {
      queryClient.invalidateQueries("adminOrders");
    },
  });

  useEffect(() => {
    // Sum the quantities of the picked items.
    let pickedQuantity = 0;
    Object.values(picked).forEach((quantity) => {
      pickedQuantity += quantity;
    });

    // Sum the quantities of the order items.
    let orderQuantity = 0;
    Object.values(order?.order_rows).forEach((item) => {
      orderQuantity += item.quantity;
    });

    if (pickedQuantity === orderQuantity) {
      setEverythingPicked(true);
      return;
    }

    setEverythingPicked(false);
    setShipmentMethodOptionsCountry(order?.country_id);
  }, [order, order?.order_rows, picked, setShipmentMethodOptionsCountry]);

  if (!user?.admin) {
    return <></>;
  }

  if (Object.keys(shipments).length === 0) {
    return (
      <>
        <Message>
          <Form>
            <Form.Select
              label="Shipment Method"
              options={shipmentMethodOptions}
              value={shipmentMethodId}
              placeholder="Select Shipment Method"
              onChange={(e, { value }) => setShipmentMethodId(value)}
            />
            <Form.Input
              label="Tracking Number"
              placeholder="Tracking Number"
              value={trackingReference}
              onChange={(e, { value }) => setTrackingReference(value)}
              required={shipmentMethods[shipmentMethodId]?.tracking_required}
              disabled={!shipmentMethods[shipmentMethodId]?.tracking_required}
            />
            <Form.Button
              content="Ship"
              icon="shipping fast"
              labelPosition="left"
              color="green"
              disabled={
                shipmentMethodId === 0 ||
                (shipmentMethods[shipmentMethodId]?.tracking_required &&
                  trackingReference === "") ||
                !everythingPicked
              }
              onClick={() => {
                mutation.mutate({
                  orderId: order.id,
                  data: {
                    order_id: order.id,
                    shipment_method_id: shipmentMethodId,
                    tracking_reference: trackingReference,
                  },
                });
              }}
            />
          </Form>
        </Message>
      </>
    );
  }

  return (
    <Message>
      <br />
      <Form>
        <Form.Checkbox
          value={removeShipmentConfirmation}
          label="Remove Shipment"
          onChange={(e, { value }) =>
            setRemoveShipmentConfirmation(!removeShipmentConfirmation)
          }
        />
        <Form.Button
          content="Remove"
          disabled={!removeShipmentConfirmation}
          icon="trash"
          labelPosition="left"
          color="red"
          onClick={() => {
            removeShipmentMutation.mutate(Object.values(shipments)[0].id);
          }}
        />
      </Form>
    </Message>
  );
};

export default React.memo(AdminOrderStatus);
