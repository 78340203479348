import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useAtom } from "jotai";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Grid, Icon, Menu, Table } from "semantic-ui-react";
import {} from "../state";
import { userAtom } from "../atoms/authAtom";
import Title from "../components/Title.js";
import AdminOrderTableRow from "./AdminOrderTableRow.js";

import ErrorPage from "../ErrorPage.js";

const fetchAdminOrders = async () => {
  // const sortOrder = sortDirection === "ascending" ? "" : "-";
  const sortOrder = "-";
  const sortColumn = "id";
  const sortParam = `sort_by=${sortOrder}${sortColumn}`;

  const response = await fetch(`/api/orders?${sortParam}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const AdminOrders = () => {
  const [user] = useAtom(userAtom);

  const [orders, setOrders] = useState([]);
  const [shipments, setShipments] = useState([]);

  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("descending");
  const [shippedFilter, setShippedFilter] = useState(false);

  const queryClient = useQueryClient();

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["adminOrders"],
    queryFn: fetchAdminOrders,
  });

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (data && data.orders) {
        if (shippedFilter) {
          const filteredOrders = data.orders.filter((order) => order.shipped);
          setOrders(filteredOrders);
        } else {
          const filteredOrders = data.orders.filter((order) => !order.shipped);
          setOrders(filteredOrders);
        }
        setShipments(data.shipments);
      }
    });
  }, [data, shippedFilter]);

  if (!user?.admin) {
    return <ErrorPage />;
  }

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error: {error.message}</p>;

  return (
    <div>
      <Grid>
        <Grid.Column width={8}>
          <Title title="Admin: Orders" />
        </Grid.Column>
        <Grid.Column width={8} textAlign="right">
          <Menu compact icon>
            <Menu.Item
              name="dolly"
              active={!shippedFilter}
              onClick={() => {
                setShippedFilter(false);
              }}
            >
              <Icon name="dolly" />
            </Menu.Item>
            <Menu.Item
              name="shipping fast"
              active={shippedFilter}
              onClick={() => {
                setShippedFilter(true);
              }}
            >
              <Icon name="shipping fast" />
            </Menu.Item>
            <Menu.Item
              name="refresh"
              onClick={() => {
                refetch();
              }}
            >
              <Icon name="refresh" loading={isLoading} />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid>
      <Table celled stackable striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === "id" ? sortDirection : null}
              // onClick={() => {
              //   setSortColumn('id');
              //   setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              // }}
            >
              ID
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "created_at" ? sortDirection : null}
              // onClick={() => {
              //   setSortColumn('created_at');
              //   setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              // }}
            >
              Date
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "customer.name" ? sortDirection : null}
              // onClick={() => {
              //   setSortColumn('customer.name');
              //   setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              // }}
            >
              Customer
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "total" ? sortDirection : null}
              // onClick={() => {
              //   setSortColumn('total');
              //   setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              // }}
            >
              Total
            </Table.HeaderCell>
            <Table.HeaderCell
              collapsing
              sorted={sortColumn === "paid" ? sortDirection : null}
              // onClick={() => {
              //   setSortColumn('paid');
              //   setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              // }}
            >
              Paid
            </Table.HeaderCell>
            <Table.HeaderCell
              collapsing
              sorted={
                sortColumn === "shipment_method.name" ? sortDirection : null
              }
              // onClick={() => {
              //   setSortColumn('shipment_method.name');
              //   setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              // }}
            >
              Shipment Method
            </Table.HeaderCell>
            <Table.HeaderCell
              collapsing
              sorted={sortColumn === "shipped" ? sortDirection : null}
              // onClick={() => {
              //   setSortColumn('shipped');
              //   setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
              // }}
            >
              Shipped
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {orders.map((order) => (
            <AdminOrderTableRow
              key={order.id}
              order={order}
              isShipped={order.shipped}
              refetch={refetch}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default React.memo(AdminOrders);
