import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";
import { Dimmer, Segment } from "semantic-ui-react";
import "fomantic-ui-less/semantic.less";
import "./App.css";
import { useAtom } from "jotai";

import Footer from "./components/Footer";
import LocaleModal from "./components/LocaleModal";
import LoginCheck from "./components/LoginCheck";
import TopBar from "./components/TopBar";

import useSitesConfig from "./hooks/useSitesConfig";
import useBundle from "./hooks/useBundle";
import useResponsive from "./hooks/useResponsive";
import useTitle from "./hooks/useTitle";
import useWebpSupport from "./hooks/useWebpSupport";
import useWebSocket from "./hooks/useWebSocket";

import { cartWidth, leftMenuWidth, mobileSize, responsiveAtom } from "./atoms/responsiveAtom";

import LeftMenu from "./LeftMenu";
import SideCart from "./SideCart";

const App = () => {
  const { handleDimmerVisibility } = useResponsive();
  const { sendMessage } = useWebSocket();

  const [responsive] = useAtom(responsiveAtom);

  const queryClient = new QueryClient();

  useSitesConfig();
  useBundle();
  useTitle();
  useWebpSupport();

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollRestoration />
      <LoginCheck />
      <TopBar />
      <LeftMenu width={leftMenuWidth} visible={responsive.leftMenuVisible} />
      <LocaleModal />
      <Segment
        basic
        style={{
          minHeight: "100vh",
          marginLeft: responsive.leftMenuVisible && window.innerWidth > mobileSize ? leftMenuWidth : "0",
          marginRight: responsive.cartVisible && window.innerWidth > mobileSize ? cartWidth : "0",
          marginTop: responsive.topMargin,
        }}
      >
        <Outlet />
        <Dimmer
          active={(responsive.leftMenuVisible || responsive.cartVisible) && window.innerWidth <= mobileSize}
          onClick={handleDimmerVisibility}
          style={{ zIndex: 100 }}
        />
      </Segment>
      <SideCart
        width={window.innerWidth <= mobileSize ? "100%" : cartWidth}
        visible={responsive.cartVisible}
        topMargin={responsive.topMargin}
      />
      <Segment
        inverted
        style={{
          marginLeft: responsive.leftMenuVisible && window.innerWidth > mobileSize ? leftMenuWidth : "0",
          marginRight: responsive.cartVisible && window.innerWidth > mobileSize ? cartWidth : "0",
          borderRadius: 0,
        }}
      >
        <Footer />
      </Segment>
    </QueryClientProvider>
  );
};

export default App;
