import React from "react";
import { useAtom } from "jotai";
import { Button, Dropdown, Flag, Header, HeaderContent, Icon, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  countryAtom,
  countriesAtom,
  currencyAtom,
  currenciesAtom,
  languageAtom,
  languagesAtom,
  localeModalOpenAtom,
  localeOverrideAtom,
} from "../state.js";

const LocaleModal = () => {
  const [country, setCountry] = useAtom(countryAtom);
  const [countries] = useAtom(countriesAtom);
  const [currency, setCurrency] = useAtom(currencyAtom);
  const [currencies] = useAtom(currenciesAtom);
  const [language, setLanguage] = useAtom(languageAtom);
  const [languages] = useAtom(languagesAtom);
  const [localeModalOpen, setLocaleModalOpen] = useAtom(localeModalOpenAtom);
  const [localeOverride, setLocaleOverride] = useAtom(localeOverrideAtom);

  const { t, i18n } = useTranslation();

  const countryOptions = Object.values(countries)
    .map((country) => ({
      key: country.id,
      text: `${country.name} (${country.vat_percent}%)`,
      value: country.id,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const currencyOptions = Object.values(currencies)
    .map((currency) => ({
      key: currency.id,
      text: `${currency.iso4217} (${currency.name})`,
      value: currency.id,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const handleSave = () => {
    setLocaleOverride(true);
    setLocaleModalOpen(false);
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      centered={true}
      size="tiny"
      open={!localeOverride || localeModalOpen}
      onClose={() => {
        setLocaleOverride(true);
        setLocaleModalOpen(false);
      }}
    >
      <Modal.Content>
        <Modal.Description>
          <Header as="h3">
            <Icon name="globe" />
            <HeaderContent>{t("language")}</HeaderContent>
          </Header>

          <Flag
            key="swedish"
            name="se"
            onClick={() => {
              setLanguage(Object.values(languages).find((language) => language.name === "Swedish")?.id);
              i18n.changeLanguage("sv");
            }}
            className="medium"
            style={{
              cursor: "pointer",
              opacity:
                language === Object.values(languages).find((language) => language.name === "Swedish")?.id ? 1 : 0.5,
            }}
          />

          <Flag
            key="english"
            name="gb"
            onClick={() => {
              setLanguage(Object.values(languages).find((language) => language.name === "English")?.id);
              i18n.changeLanguage("en");
            }}
            className="medium"
            style={{
              cursor: "pointer",
              opacity:
                language === Object.values(languages).find((language) => language.name === "English")?.id ? 1 : 0.5,
            }}
          />

          <Header as="h3">
            <Icon name="percent" />
            <HeaderContent>{t("country_vat")}</HeaderContent>
          </Header>
          <Dropdown
            onChange={(e, { value }) => setCountry(value)}
            value={country}
            placeholder="Select Country"
            fluid
            search
            selection
            options={countryOptions}
          />

          <Header as="h3">
            <Icon name="money" />
            <HeaderContent>{t("currency")}</HeaderContent>
          </Header>
          <Dropdown
            onChange={(e, { value }) => setCurrency(value)}
            value={currency}
            placeholder="Select Currency"
            fluid
            search
            selection
            options={currencyOptions}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleSave}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LocaleModal;
