import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { Grid, Image } from "semantic-ui-react";
import Title from "./components/Title.js";
import { bundleFinishedAtom, brandsAtom } from "./state.js";

const BrandsPage = ({}) => {
  const [bundleFinished, setBundleFinished] = useAtom(bundleFinishedAtom);
  const [brands] = useAtom(brandsAtom);
  const [sortedBrands, setSortedBrands] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (bundleFinished) {
      setSortedBrands(Object.values(brands).sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [bundleFinished, brands]);

  return (
    <React.Fragment>
      <Title title={t("brands")} />
      <Grid>
        {sortedBrands.map((brand) => (
          <Grid.Column
            key={brand.id}
            textAlign="center"
            verticalAlign="middle"
            widescreen={2}
            computer={3}
            mobile={5}
            // style={{ width: '250px' }}
          >
            <Image
              src={`/images/brands/${brand.id}.png`}
              wrapped
              // ui={false}
              style={{ margin: "0.5em" }}
              // style={{ objectFit: 'contain' }}
              as={Link}
              to={`/brands/${brand.tag}`}
            />
          </Grid.Column>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default BrandsPage;
