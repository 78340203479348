import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Accordion, Menu } from "semantic-ui-react";
import { languageAtom } from "./state.js";
import { userAtom } from "./atoms/authAtom";
import { AdminMenu } from "./AdminMenu.js";
import { leftMenuAtom } from "./atoms/leftMenuAtom";
import { responsiveAtom } from "./atoms/responsiveAtom.js";
import useResponsive from "./hooks/useResponsive.js";

const LeftMenu = ({ width, visible, topMargin }) => {
  const [language] = useAtom(languageAtom);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTag, setActiveTag] = useState("");
  const [responsive] = useAtom(responsiveAtom);
  const [user] = useAtom(userAtom);

  const { handleDimmerVisibility } = useResponsive();

  const location = useLocation();
  const { t } = useTranslation();

  const [organizedData] = useAtom(leftMenuAtom);

  useEffect(() => {
    if (location.pathname.startsWith("/tags/")) {
      setActiveTag(location.pathname.substring(6));
    }
  }, [location]);

  return (
    <Accordion
      as={Menu}
      inverted
      vertical
      fluid
      style={{
        display: visible ? "block" : "none",
        position: "fixed",
        top: responsive.topMargin,
        maxWidth: width,
        width: width,
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
        marginTop: "0px",
        paddingTop: responsive.topMargin === "0px" ? "0px" : "0.3em",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "80px",
        borderRadius: 0,
        border: "none",
        zIndex: 1000,
      }}
    >
      {user?.admin && (
        <Menu.Item key="admin">
          <Accordion.Title
            active={activeIndex === "admin"}
            index="admin"
            content="Admin"
            onClick={(e, titleProps) => {
              const { index } = titleProps;
              setActiveIndex(activeIndex === "admin" ? null : index);
            }}
            style={{ fontSize: "1.2em", fontWeight: "bold" }}
          />
          <Accordion.Content active={activeIndex === "admin"}>
            <Menu.Menu>
              {AdminMenu.map((item, index) => (
                <Menu.Item
                  key={index}
                  name={item}
                  as={Link}
                  to={"/admin/" + item}
                  active={location.pathname === "/admin/" + item}
                  style={{ fontSize: "1em", paddingLeft: "0px" }}
                  onClick={handleDimmerVisibility}
                >
                  {t("admin-" + item)}
                </Menu.Item>
              ))}
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      )}
      <Menu.Item key="home" style={{ fontSize: "1.2em", fontWeight: "bold", marginLeft: "-0.3em" }}>
        <Link to="/" onClick={handleDimmerVisibility}>
          {t("home")}
        </Link>
      </Menu.Item>
      <Menu.Item key="brands" style={{ fontSize: "1.2em", fontWeight: "bold", marginLeft: "-0.3em" }}>
        <Link to="/brands" onClick={handleDimmerVisibility}>
          {t("brands")}
        </Link>
      </Menu.Item>
      {organizedData.topLevel.map((group, index) => (
        <Menu.Item key={group.tag}>
          <Accordion.Title
            active={activeIndex === group.tag}
            index={group.tag}
            content={group.names[language]}
            onClick={(e, titleProps) => {
              const { index } = titleProps;
              setActiveIndex(activeIndex === group.tag ? null : index);
            }}
            style={{ fontSize: "1.2em", fontWeight: "bold" }}
          />
          <Accordion.Content active={activeIndex === group.tag}>
            <Menu.Menu style={{ marginTop: "0.4em" }}>
              {organizedData.childrenMap[group.id]?.map((child) => (
                <Menu.Item
                  key={child.tag}
                  name={child.tag}
                  as={Link}
                  to={"/tags/" + child.tag}
                  active={activeTag === child.tag}
                  style={{ fontSize: "1em", paddingLeft: "0.5em" }}
                  onClick={handleDimmerVisibility}
                >
                  {child.names[language] ? child.names[language] : child.tag}
                </Menu.Item>
              ))}
            </Menu.Menu>
          </Accordion.Content>
        </Menu.Item>
      ))}
    </Accordion>
  );
};

export default LeftMenu;
